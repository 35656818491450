import React from 'react'
import cx from 'classnames'

export interface HighlightProps {
  /** Color from theme that is used for highlighting */
  highlightColor?: string;
  className?: string;
  /** Full string part of which will be highlighted */
  children: string;
}

export const Highlight: React.FC<HighlightProps> = function Highlight({
  highlightColor,
  className,
  children,
  ...other
}) {
  className = cx(
    'block text-xs font-semibold tracking-widest uppercase',
    className
  )

  return (
    <span className={className} {...other}>
      {children}
    </span>
  )
}
