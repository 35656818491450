import React from 'react'
import { graphql, PageProps } from 'gatsby'
// import { Hero } from '../components/hero'
import { Highlight } from '../components/text/highlight'
import Layout from '../layouts'
import { SEO } from '../components/seo'

export default function InfoRoute(props: PageProps) {
  const info = props.data.info

  return (
    <Layout location={props.location} heros={info.hero} herosFallbackTitle={info.title}>
      <div className="bg-white dark:bg-gray-950">
        <div className="wrapper">
          <div className="container relative flex flex-col py-20 mx-auto md:flex-row">
            <div className="mx-auto prose dark:prose-dark">
              <Highlight className="-mb-8 text-primary">
                {info.teaserSubtitle}
              </Highlight>{' '}
              <h2>
                {info.teaserTitle}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: info.body.childMarkdownRemark.html
                }}
                className="mx-auto mt-6"
              />
              <h3>Together, we are UMG, the Universal Music Group.</h3>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => (
  <SEO />
)

export const pageQuery = graphql`
  query {
    info: contentfulCustomPage(slug: { eq: "info" }) {
      title
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
      teaserTitle
      teaserSubtitle
      hero {
        ...HeroFragment
      }
    }
  }
`
